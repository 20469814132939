.writeMsg_mainDiv{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}

.writeMsg_msg{
    width: 100%;
    resize: none;
}

.resize{
    height: 120px;
}

.fullScreen{
    height: 47vh;
}

.MsgInput_style{
    border: none;
    outline: none;
}

.writeMsg_label{
    color: #252525;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
}

.writeMsg_sendBtn{
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    font-family: Nunito;
    border-radius: 24px;
    text-transform: uppercase;
}

.icon_size{
    font-size: 18px;
}

.MsgClose_icon{
    font-size: 14px;
}

.addCaption{
    border: none !important;
    outline: none;
    border-bottom: 1px solid #054694 !important;
    border-radius: 2px !important; 
}

.Attachment_Icon{
    font-size: 18px;
    color: #054694;
}

.Attachment_closeIcon{
    font-size: 14px;
    color: #054694;
}

.Attachment_fileName{
    color: #054694;
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
}

.Attachment_fileSize{
    color: rgb(123, 123, 123);
    font-family: Nunito;
    font-size: 12px;
    font-weight: 600;
}

.Attachment_section{
    border-radius: 10px;
}

.Attachment_ScrollDiv{
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
}

.attachment_countBorder{
    border-radius: 15px;
    border: 1px solid #054694;
    background-color: #FFF;
}

.attachment_countBg{
    background-color: #054694;
    color:#fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
}

.attachment_count{
    color: #054694;
    font-family: Nunito;
    font-size: 13px;
    font-weight: 600;
    padding: 2px 5px 0px 2px;
}

.fullscreen_back_color {
    background-color: #fff;
    border: none !important;
}

.fullScreen_attachmentIcon{
    font-size: 16px;
    color: #054694;
}

.fullScreen_attachmentName{
    color: #054694;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.fullScreen_attachmentSize{
    color: rgb(52, 52, 52);
    font-family: Nunito;
    font-size: 12px;
    font-weight: 600;
}

.fullScreen_closeIcon{
    font-size: 12px;
    color: #054694;
}

.attachmentCaption{
    color: rgb(52, 52, 52);
    font-family: Nunito;
    font-size: 13px;
    font-weight: 600;
}

.fullScreen_attachmentScrollDiv{
    height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    background-color: #EDF2F7;
    border-radius: 4px;
}

.attachment_noFiles{
    color: #054694;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
}