
.Circle-Border{
    border-radius: 50%;
    padding: 2px;
}

.NotViewed{
    border: 2px solid #00BE00;
}

.Viewed{
    border: 2px solid #ABABAB;
}

.Bar-Name{
    color: #202020;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
}

.Bar-Role{
    color: #154998;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
}

.Bar-MsgContent{
    color: #3C3C3C;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
}