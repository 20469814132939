
.Search_Bar {
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;

}

/* .form-field {
    position: relative;
    display: inline-block;
} */

.Search_icon {
    position: absolute;
    top: 35%;
    left: 18px;
    font-size: 16px;
}

.Serach_input {
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    width: 100%;
    padding: 7px 40px;
}

.Search_Advance_icon{
    position: absolute;
    /* bottom: 20px; */
    top: 35%;
    right: 23px;
    font-size: 18px;
}

.display-none {
    display: none;
}

.rmsc .dropdown-heading {
    background-color: #EDF2F7 !important;
}

.Select_label {
    font-size: 11px;
    white-space: nowrap;
    font-weight: 800;
    font-family: Nunito;
}


.input-size {
    width: 25%;
}

.font-size-modal {
    font-size: 13px;
    font-weight: 600;
    font-family: Nunito;
}

.date-color {
    font-size: 12px;
    font-weight: 600;
    border-radius: 0px 0px 10px 0px;
    background: #054694;
    color: #fff;
    padding: 8px;
    width: 10%;
}

.border-bottom-blue{
    border-bottom: 2px solid #154998;;
}

.modal-dropdown-size{
    font-size: 15px;
    font-weight: 600;
    font-family: Nunito;
}
