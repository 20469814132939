
.Unopened-Label{
    color: #252525;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
}

.Unopened-Arrow{
    font-size: 12px;
    background-color: #DCDCDC;
    font-weight: 600;
}

.UpArrow{
    border-radius: 50%;
    padding: 5px;
}

.LeftArrow{
    border-radius: 0 6px 6px 0;
    padding: 8px;
    text-align: center;
}

.RightArrow{
    border-radius: 6px 0 0 6px ;
    padding: 8px;
}

.border_bottom{
    border-bottom: 1px solid #252525;
}