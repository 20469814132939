
.background-color-katchup {
    background-color: #F4F4F4;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.icon-font-katchup {
    background-color: #252525;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.pad-katchup {
    padding-right: 15px;
}

.mar-katchup {
    margin-top: -25px;
}

.message-icon-color {
    color: #054694;
    font-size: 18px;
}

.message_scroll_height {
    height: 33vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.message_scroll_height::-webkit-scrollbar {
    display: none;
}

.fixed_write_msg {
    position: sticky;
    bottom: 0%
}

/* .msg_height {
    height: 34vh;
    overflow-x: hidden;
    overflow-y: scroll;
} */

/* .msg_height::-webkit-scrollbar {
    display: none;
} */

