.News-Container{
    /* max-height: 60vh; */
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

/* .News-Label{
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 23px;
    font-weight: 600;
} */

.News-Icon{
    font-size: 20px;
    color: #585858;
}

.News-ArrowIcon{
    font-size: 12px;
}

.News-ViewMore{
    color: #505050;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}

.News-contentImg{
    width: 115px;
    height: 84px;
    object-fit: contain;
    border-radius: 6px;
}

.News-Content{
    color: #1D1D1D;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.News-Date{
    color: #5B5B5B;
    font-family: Nunito;
    font-size: 11px;
    font-weight: 400;
}

.New-contentScroll{
    /* max-height: 120px;
    overflow-y: scroll;
    overflow-x: hidden; */
}

.News-List{
    /* width: 477px; */
    /* height: 266px; */
    border-radius: 10px;
    background: var(--gray-f-4, #F4F4F4);
    padding: 20px;
}