@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.font-Family-Nunito {
    font-family: Nunito;
}

.Modal_Style {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(180deg, rgba(1, 40, 100, 0.40) 0%, rgba(0, 32, 81, 0.40) 100%), linear-gradient(270deg, #2630A8 38.08%, #1C84DC 78.1%, #2630A8 103.12%), linear-gradient(180deg, #0043A8 0%, #002051 100%);
}
