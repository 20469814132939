
.Label_Font {
    color: var(--Primary-Text, var(--black-25, #252525));
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.Input_Style {
    border: 1px solid #054694;
    border-radius: 4px;
    padding: 6px;
    outline: none;
    font-family: Nunito;
    font-size: 14px;
}

.err-msg-false {
    color: red;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.err-msg-true {
    color: green;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.Input_Style::placeholder{
    font-size: 15px;
    padding: 0px 5px;
}

.Input_Back_Color {
    background-color: #EDF2F7;
    border: 1px solid #BFD0E4;
}

.Dropdown_Back_Color {
    /* background-color: #EDF2F7 !important; */
    border: 1px solid #BFD0E4;
    border-radius: 5px;
}

.Input_Back_Color:focus {
    background-color: #fff;
    border: 1px solid #EDF2F7;
}
