.contact_dropDown{
    width: 100%;
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.contact_Icon{
    font-size: 21px;
    font-weight: 500;
    color:  #054694;
    border-radius: 5px;
}

.contact_category{
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 17px;
    font-weight: 600;
}

.contact_border{
    border-bottom: 1px solid #bababa;
    padding: 3px 0 8px 0;
}

.contact_msgCount{
    background-color: #054694;
    border-radius: 50%;
    font-family: Nunito;
    font-size: 10px;
    font-weight: 700;
    padding: 4px 5px;
    color: #fff;
}

.contact-height {
    height: 42vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.contact-height::-webkit-scrollbar {
    display: none;
}

.icon-back-contact {
    background-color: #fff;
    padding: 5px;
}

.dropdown-back {
    border: none;
    background-color: #fff;
    height: 5.5vh;
    padding: 0px 10px;
    border-radius: 5px;
}

.contact-background-style {
    background-color: #fff;
    width: 72%;
    padding: 5px 10px;
    border-radius: 5px;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.pc-font {
    color: #054694;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.dropdown-width {
    width: 20%;
    left: 6.3%;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* .dropdown-width::-webkit-scrollbar {
    display: none;
} */

.pc-font-dropdown {
    color: #252525;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
}

.pc-font-red-dropdown {
    color: #B60228;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
}

.dropdown-name-font {
    color: #585858;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}

.dropdown-name-font:hover {
    background-color: #E7F5FE;
    cursor: pointer;
}

.contact-dropdown-width {
    width: 11%;
    left: 23%;
    background-color: #fff;
    border-radius: 5px;
}

.plus-icon {
    color: #F00; 
    font-size: 20px;
}

.ref-font {
    color: #009E54;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
}

.ref-font-style {
    color: #252525;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
}

.ref-para-font {
    color: #585858;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}

.Application-image {
    width: 52px;
    height: 52px;
}