@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ur6p03');
  src:  url('fonts/icomoon.eot?ur6p03#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ur6p03') format('truetype'),
    url('fonts/icomoon.woff?ur6p03') format('woff'),
    url('fonts/icomoon.svg?ur6p03#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-KP_01-Home:before {
  content: "\e900";
}
.icon-KP_02-Write-Letter:before {
  content: "\e901";
}
.icon-KP_03-KMail:before {
  content: "\e902";
}
.icon-KP_04-Katchup:before {
  content: "\e903";
}
.icon-KP_05-Kall:before {
  content: "\e904";
}
.icon-KP_06-KDirectory:before {
  content: "\e905";
}
.icon-KP_07-KDocuments:before {
  content: "\e906";
}
.icon-KP_08-KNews:before {
  content: "\e907";
}
.icon-KP_09-Travel:before {
  content: "\e908";
}
.icon-KP_10-Broadcast:before {
  content: "\e909";
}
.icon-KP_11-KPay:before {
  content: "\e90a";
}
.icon-KP_12-KWallet:before {
  content: "\e90b";
}
.icon-KP_13-Kalender:before {
  content: "\e90c";
}
.icon-KP_14-KCommerce:before {
  content: "\e90d";
}
.icon-KP_15-Settings:before {
  content: "\e90e";
}
.icon-KP_16-Facepro:before {
  content: "\e90f";
}
.icon-KP_17-Klassified:before {
  content: "\e910";
}
.icon-KP_18-Logout:before {
  content: "\e911";
}
.icon-KP_19-Menu:before {
  content: "\e912";
}
.icon-KP_20-Menu-Open:before {
  content: "\e913";
}
.icon-KP_21-Post-Icon-2:before {
  content: "\e914";
}
.icon-KP_22-Down-Arrow-22:before {
  content: "\e916";
}
.icon-KP_22-Down-Arrow-27:before {
  content: "\e917";
}
.icon-KP_23-Up-Arrow:before {
  content: "\e918";
}
.icon-KP_24-Arrows:before {
  content: "\e919";
}
.icon-KP_25-Left-Arrow:before {
  content: "\e91a";
}
.icon-KP_26-Right-Arrow:before {
  content: "\e91b";
}
.icon-KP_27-Arrow:before {
  content: "\e91c";
}
.icon-KP_28-Arrow:before {
  content: "\e91d";
}
.icon-KP_29-Delete:before {
  content: "\e91e";
}
.icon-KP_30-Search:before {
  content: "\e91f";
}
.icon-KP_31-More:before {
  content: "\e920";
}
.icon-KP_32-Grid:before {
  content: "\e921";
}
.icon-KP_33-List:before {
  content: "\e922";
}
.icon-KP_34-Cancel:before {
  content: "\e923";
}
.icon-KP_35-Info:before {
  content: "\e924";
}
.icon-KP_36-Attach:before {
  content: "\e925";
}
.icon-KP_37-Attach:before {
  content: "\e926";
}
.icon-KP_38-Tick:before {
  content: "\e927";
}
.icon-KP_39-Circle:before {
  content: "\e928";
}
.icon-KP_40-Invisible:before {
  content: "\e929";
}
.icon-KP_41-Visible:before {
  content: "\e92a";
}
.icon-KP_42-Fav .path1:before {
  content: "\e92b";
  color: rgb(236, 138, 25);
}
.icon-KP_42-Fav .path2:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(208, 112, 0);
}
.icon-KP_43-Fav:before {
  content: "\e92d";
}
.icon-KP_44-Copy:before {
  content: "\e92e";
}
.icon-KP_45-Add:before {
  content: "\e92f";
}
.icon-KP_46-Outgoing:before {
  content: "\e930";
}
.icon-KP_47-Incoming:before {
  content: "\e931";
}
.icon-KP_48-Missed:before {
  content: "\e932";
}
.icon-KP_49-Speaker-off:before {
  content: "\e933";
}
.icon-KP_50-Speaker-on:before {
  content: "\e934";
}
.icon-KP_52-Mic-Off:before {
  content: "\e935";
}
.icon-KP_53-Mic-on:before {
  content: "\e936";
}
.icon-KP_54-Hang-fill:before {
  content: "\e937";
}
.icon-KP_55-Hang-Line:before {
  content: "\e938";
}
.icon-KP_56-Location-Off:before {
  content: "\e939";
}
.icon-KP_57-Location-On:before {
  content: "\e93a";
}
.icon-KP_58-Location:before {
  content: "\e93b";
}
.icon-KP_59-More-Lined:before {
  content: "\e93c";
}
.icon-KP_60-Mail-Unread:before {
  content: "\e93d";
}
.icon-KP_62-Mail-Arrow:before {
  content: "\e93e";
}
.icon-KP_63-Mail-Arrow:before {
  content: "\e93f";
}
.icon-KP_64-Chat-Deselect:before {
  content: "\e940";
}
.icon-KP_65-Chat-Select:before {
  content: "\e941";
}
.icon-KP_66-Photo .path1:before {
  content: "\e942";
  color: rgb(10, 201, 99);
}
.icon-KP_66-Photo .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(84, 217, 146);
}
.icon-KP_66-Photo .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-KP_66-Photo .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-KP_67-Camera:before {
  content: "\e946";
}
.icon-KP_68-Video:before {
  content: "\e947";
}
.icon-KP_69-Document:before {
  content: "\e948";
}
.icon-KP_70-Transfer:before {
  content: "\e949";
}
.icon-KP_71-Save:before {
  content: "\e94a";
}
.icon-KP_72-bell:before {
  content: "\e94b";
}
.icon-KP_73-rply:before {
  content: "\e94c";
}
.icon-KP_74-fwd:before {
  content: "\e94d";
}
.icon-KP_75-translate:before {
  content: "\e94e";
}
.icon-KP_76-Pri-Low:before {
  content: "\e94f";
}
.icon-KP_77-pri-medium:before {
  content: "\e950";
}
.icon-KP_78-Pri-High:before {
  content: "\e951";
}
.icon-KP_79-Printer:before {
  content: "\e952";
}
.icon-KP_80-Filter:before {
  content: "\e953";
}
.icon-KP_81-Comment:before {
  content: "\e954";
}
.icon-KP_82-Lock:before {
  content: "\e955";
}
.icon-KP_83-option:before {
  content: "\e956";
}
.icon-KP_85-Privacy-Settings:before {
  content: "\e957";
}
.icon-KP_85-Privacy-Settings-1:before {
  content: "\e958";
}
.icon-KP_86-Change-Pswd:before {
  content: "\e959";
}
.icon-KP_87-Change-Pin:before {
  content: "\e95a";
}
.icon-KP_88-Bus:before {
  content: "\e95b";
}
.icon-KP_89-flight:before {
  content: "\e95c";
}
.icon-KP_90-Train:before {
  content: "\e95d";
}
.icon-KP_91-Hotel:before {
  content: "\e95e";
}
.icon-KP_92-Merchants:before {
  content: "\e95f";
}
.icon-KP_93-By-Category:before {
  content: "\e960";
}
.icon-KP_94-Special-Offer:before {
  content: "\e961";
}
.icon-KP_95-Write-Mail-Temp:before {
  content: "\e962";
}
.icon-KP_96-Edit:before {
  content: "\e963";
}
.icon-KP_97-Clarify:before {
  content: "\e964";
}
.icon-KP_98-Font-Style:before {
  content: "\e965";
}
.icon-KP_99-Theme-color:before {
  content: "\e966";
}
.icon-KP_100-Notification:before {
  content: "\e967";
}
.icon-KP_101-Katchup-Notification:before {
  content: "\e968";
}
.icon-KP_102-Clear-Chat:before {
  content: "\e969";
}
.icon-KP_103-Secure-Chat:before {
  content: "\e96a";
}
.icon-KP_104-Signature:before {
  content: "\e96b";
}
.icon-KP_105-Instant-Reply:before {
  content: "\e96c";
}
.icon-KP_106-User:before {
  content: "\e96d";
}
.icon-KP_107-User-Add:before {
  content: "\e96e";
}
.icon-KP_108-User-Unknown:before {
  content: "\e970";
}
.icon-KP_109-User-Block:before {
  content: "\e971";
}
.icon-KP_110-User-Exit:before {
  content: "\e972";
}
.icon-KP_111-Group:before {
  content: "\e973";
}
.icon-KP_112-Group-Add:before {
  content: "\e974";
}
.icon-KP_113-Group-Exit:before {
  content: "\e975";
}
.icon-KP_117-User-Tick:before {
  content: "\e976";
}
.icon-KP_118-Note:before {
  content: "\e977";
}
.icon-KP_119-Forward:before {
  content: "\e978";
}
.icon-KP_120-KShare:before {
  content: "\e979";
}
.icon-KP_121-No-Content:before {
  content: "\e97a";
}
.icon-KP_122-Delete-after-read:before {
  content: "\e97b";
}
.icon-KP_123-Delete-Timer:before {
  content: "\e97c";
}
.icon-KP_124-Schedule:before {
  content: "\e97d";
}
.icon-KP_125-Fingerprint:before {
  content: "\e97e";
}
.icon-KP_126-Faceid:before {
  content: "\e97f";
}
.icon-KP_127-System:before {
  content: "\e980";
}
.icon-KP_128-Phone:before {
  content: "\e981";
}
.icon-KP_129-Down .path1:before {
  content: "\e982";
  color: rgb(1, 0, 0);
}
.icon-KP_129-Down .path2:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-KP_130-Down-Arrow-Subtracted .path1:before {
  content: "\e984";
  color: rgb(1, 0, 0);
}
.icon-KP_130-Down-Arrow-Subtracted .path2:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-KP_131-Down-Arrow:before {
  content: "\e986";
}
.icon-KP_132-UP-arrow:before {
  content: "\e987";
}
.icon-KP_133-Sleeper:before {
  content: "\e988";
}
.icon-KP_134-Seater:before {
  content: "\e989";
}
.icon-KP_135-Location-Wing:before {
  content: "\e98a";
}
.icon-KP_136-Close:before {
  content: "\e98b";
}
.icon-KP_137-KBookings:before {
  content: "\e98c";
}
.icon-KP_138-KMail-Setting:before {
  content: "\e98d";
}
.icon-KP_139-KNews-Setting:before {
  content: "\e98e";
}
.icon-KP_140-KNews-Add:before {
  content: "\e98f";
}
.icon-KP_141-Write-Letter:before {
  content: "\e990";
}
.icon-KP_142-Letter-Pad:before {
  content: "\e991";
}
.icon-KP_143-Raily-Icon:before {
  content: "\e992";
}
.icon-KP_144---More-Vertical:before {
  content: "\e993";
}
.icon-KP_145-Seat-Verticlle:before {
  content: "\e994";
}
.icon-KP_146-Mail-Notifi-2:before {
  content: "\e995";
}
.icon-KP_147-Noti-Arrow:before {
  content: "\e996";
}
.icon-KP_148-Noti-Arrow:before {
  content: "\e997";
}
.icon-KP_149-Group-Admin:before {
  content: "\e998";
}
.icon-KP_150-Alert:before {
  content: "\e999";
}
.icon-KP_151-Play:before {
  content: "\e99a";
}
.icon-KP_152-Link:before {
  content: "\e99b";
}
.icon-KP_153-IP-Mobile:before {
  content: "\e99c";
}
.icon-KP_154-Video-call:before {
  content: "\e99d";
}
.icon-KP_155-Sim-1:before {
  content: "\e99e";
}
.icon-KP_156-Sim-2:before {
  content: "\e99f";
}
.icon-KP_157-Mobile-Blocked:before {
  content: "\e9a0";
}
.icon-KP_158-landlineblock:before {
  content: "\e9a1";
}
.icon-KP_159-facecall:before {
  content: "\e9a2";
}
.icon-KP_160-landline:before {
  content: "\e9a3";
}
.icon-KP_161-Globe:before {
  content: "\e9a4";
}
.icon-KP_162-Mail-more:before {
  content: "\e9a5";
}
.icon-KP_163-Group---smacc:before {
  content: "\e9a6";
}
.icon-KP_164-Call-history:before {
  content: "\e9a7";
}
.icon-KP_165-Contacts:before {
  content: "\e9a8";
}
.icon-KP_166---Crop:before {
  content: "\e9a9";
}
.icon-KP_167---Text:before {
  content: "\e9aa";
}
.icon-KP_168---Marker .path1:before {
  content: "\e9ab";
  color: rgb(0, 0, 0);
}
.icon-KP_168---Marker .path2:before {
  content: "\e9ac";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-KP_168---Marker .path3:before {
  content: "\e9ad";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-KP_169---Photo-Filter .path1:before {
  content: "\e9ae";
  color: rgb(0, 0, 0);
}
.icon-KP_169---Photo-Filter .path2:before {
  content: "\e9af";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-KP_169---Photo-Filter .path3:before {
  content: "\e9b0";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-KP_169---Photo-Filter .path4:before {
  content: "\e9b1";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.icon-KP_170---Admin-SMACC:before {
  content: "\e9b2";
}
.icon-KP_171---Sub-Admin-SMACC:before {
  content: "\e9b3";
}
.icon-KP_172---Upgrade-SMACC:before {
  content: "\e9b4";
}
.icon-KP_173---KGroup-SMACC:before {
  content: "\e9b5";
}
.icon-KP_173---KGroup-SMACC-1:before {
  content: "\e9b6";
}
.icon-KP_174---Bar:before {
  content: "\e9b7";
}
.icon-KP_175---Down-arrow:before {
  content: "\e9b8";
}
.icon-KP_176---Up-arrow:before {
  content: "\e9b9";
}
.icon-KP_177---Music:before {
  content: "\e9ba";
}
.icon-KP_178---Audio:before {
  content: "\e9bb";
}
.icon-KP_179---tick:before {
  content: "\e9bc";
}
.icon-KP_180---question-mark:before {
  content: "\e9bd";
}
.icon-KP_180---question-mark-1:before {
  content: "\e9be";
}
.icon-KP_181---XLS .path1:before {
  content: "\e9bf";
  color: rgb(0, 115, 59);
}
.icon-KP_181---XLS .path2:before {
  content: "\e9c0";
  margin-left: -1.001953125em;
  color: rgb(77, 157, 118);
}
.icon-KP_181---XLS .path3:before {
  content: "\e9c1";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_182--Doc .path1:before {
  content: "\e9c2";
  color: rgb(2, 99, 209);
}
.icon-KP_182--Doc .path2:before {
  content: "\e9c3";
  margin-left: -1.001953125em;
  color: rgb(78, 146, 223);
}
.icon-KP_182--Doc .path3:before {
  content: "\e9c4";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_183---PPT .path1:before {
  content: "\e9c5";
  color: rgb(224, 51, 3);
}
.icon-KP_183---PPT .path2:before {
  content: "\e9c6";
  margin-left: -1.001953125em;
  color: rgb(233, 113, 79);
}
.icon-KP_183---PPT .path3:before {
  content: "\e9c7";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_184---PDF .path1:before {
  content: "\e9c8";
  color: rgb(229, 37, 42);
}
.icon-KP_184---PDF .path2:before {
  content: "\e9c9";
  margin-left: -1.001953125em;
  color: rgb(237, 103, 106);
}
.icon-KP_184---PDF .path3:before {
  content: "\e9ca";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_185---KShare:before {
  content: "\e9cb";
}
.icon-KP_186---Fwd:before {
  content: "\e9cc";
}
.icon-KP_187---Gender:before {
  content: "\e9cd";
}
.icon-KP_188---Steering:before {
  content: "\e9ce";
}
.icon-KP_189---Recall:before {
  content: "\e9cf";
}
.icon-KP_190---Locality:before {
  content: "\e9d0";
}
.icon-KP_191---Tick:before {
  content: "\e9d1";
}
.icon-KP_192---Recent:before {
  content: "\e9d2";
}
.icon-KP_193---Add-user-Invite:before {
  content: "\e9d3";
}
.icon-KP_194--193---tick-for-invite:before {
  content: "\e9d4";
}
.icon-KP_195---USer-Sync:before {
  content: "\e9d5";
}
.icon-KP_196---Send-Paper:before {
  content: "\e9d6";
}
.icon-KP_197---Add-Member:before {
  content: "\e9d7";
}
.icon-KP_198---Change-Person:before {
  content: "\e9d8";
}
.icon-KP_199---Change-Designation:before {
  content: "\e9d9";
}
.icon-KP_200---Terminate:before {
  content: "\e9da";
}
.icon-KP_201---Hold:before {
  content: "\e9db";
}
.icon-KP_202---SMS:before {
  content: "\e9dc";
}
.icon-KP_203---Copies:before {
  content: "\e9dd";
}
.icon-KP_204---SMACC-History:before {
  content: "\e9de";
}
.icon-KP_205---Not-Sent:before {
  content: "\e9df";
}
.icon-KP_206---Share-01:before {
  content: "\e9e0";
}
.icon-KP_207---Share-02:before {
  content: "\e9e1";
}
.icon-KP_208---Share-03:before {
  content: "\e9e2";
}
.icon-KP_209---Share-04:before {
  content: "\e9e3";
}
.icon-KP_210---Prolink:before {
  content: "\e9e4";
}
.icon-KP_211---Poster:before {
  content: "\e9e5";
}
.icon-KP_212---KMail-Old:before {
  content: "\e9e6";
}
.icon-KP_213---Katchapp-Old:before {
  content: "\e9e7";
}
.icon-KP_214--KTrack:before {
  content: "\e9e8";
}
.icon-KP_215---Employee:before {
  content: "\e9e9";
}
.icon-KP_216---Work:before {
  content: "\e9ea";
}
.icon-KP_217---Confidential-copy:before {
  content: "\e9eb";
}
.icon-KP_217---Confidential-Copy-02:before {
  content: "\e9ec";
}
.icon-KP_219---Update-App:before {
  content: "\e9ed";
}
.icon-KP_220---Pura:before {
  content: "\e9ee";
}
.icon-KP_221_Create-Group:before {
  content: "\e9ef";
}
.icon-KP_222_Send:before {
  content: "\e9f0";
}
.icon-KP_223_Receiver:before {
  content: "\e9f1";
}
.icon-KP_224_Manage-Access:before {
  content: "\e9f2";
}
.icon-KP_225_Advanced-Search:before {
  content: "\e9f3";
}
.icon-KP_226_Emoji:before {
  content: "\e9f4";
}
.icon-KP_227_Secret-Message:before {
  content: "\e9f5";
}
.icon-KP_228_Group-Video-Kall:before {
  content: "\e9f6";
}
.icon-KP_229_Copies1:before {
  content: "\e9f7";
}
.icon-KP_230_Forward-with-Thread:before {
  content: "\e9f8";
}
.icon-KP_231_Post-Web .path1:before {
  content: "\e9f9";
  color: rgb(7, 72, 148);
}
.icon-KP_231_Post-Web .path2:before {
  content: "\e9fa";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_231_Post-Web .path3:before {
  content: "\e9fb";
  margin-left: -1.001953125em;
  color: rgb(3, 56, 118);
  opacity: 0.6;
}
.icon-KP_231_Post-Web .path4:before {
  content: "\e9fc";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_231_Post-Web .path5:before {
  content: "\e9fd";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_232_Select-All:before {
  content: "\e9fe";
}
.icon-KP_233_Select-All-Active .path1:before {
  content: "\e9ff";
  color: rgb(7, 72, 148);
}
.icon-KP_233_Select-All-Active .path2:before {
  content: "\ea00";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_234_Remainder:before {
  content: "\ea01";
}
.icon-KP_235_Diary:before {
  content: "\ea02";
}
.icon-KP_236_Edit:before {
  content: "\ea03";
}
.icon-KP_237_Date:before {
  content: "\ea04";
}
.icon-KP_238_Kstore:before {
  content: "\ea05";
}
.icon-KP_239_Kall-Declined:before {
  content: "\ea06";
}
.icon-KP_240-Kall01:before {
  content: "\ea07";
}
.icon-KP_241_Group-Audio-Kall:before {
  content: "\ea08";
}
.icon-KP_242_Tick .path1:before {
  content: "\ea09";
  color: rgb(0, 0, 0);
}
.icon-KP_242_Tick .path2:before {
  content: "\ea0a";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_243_Close .path1:before {
  content: "\ea0b";
  color: rgb(0, 0, 0);
}
.icon-KP_243_Close .path2:before {
  content: "\ea0c";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_244_Create-Group:before {
  content: "\ea0d";
}
.icon-KP_245_KNews:before {
  content: "\ea0e";
}
.icon-KP_246_KNews:before {
  content: "\ea0f";
}
.icon-KP_247_Create:before {
  content: "\ea10";
}
.icon-KP_248_Back-With-KPost-Bird .path1:before {
  content: "\ea11";
  color: rgb(231, 245, 254);
}
.icon-KP_248_Back-With-KPost-Bird .path2:before {
  content: "\ea12";
  margin-left: -1.001953125em;
  color: rgb(231, 245, 254);
  opacity: 0.8;
}
.icon-KP_248_Back-With-KPost-Bird .path3:before {
  content: "\ea13";
  margin-left: -1.001953125em;
  color: rgb(5, 70, 148);
}
.icon-KP_248_Back-With-KPost-Bird .path4:before {
  content: "\ea14";
  margin-left: -1.001953125em;
  color: rgb(37, 32, 91);
}
.icon-KP_248_Back-With-KPost-Bird .path5:before {
  content: "\ea15";
  margin-left: -1.001953125em;
  color: rgb(64, 184, 233);
}
.icon-KP_248_Back-With-KPost-Bird .path6:before {
  content: "\ea16";
  margin-left: -1.001953125em;
  color: rgb(236, 28, 36);
}
.icon-KP_248_Back-With-KPost-Bird .path7:before {
  content: "\ea17";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_249_Kall-Info:before {
  content: "\ea18";
}
.icon-KP_250:before {
  content: "\ea19";
}
.icon-KP_251_Accepted .path1:before {
  content: "\ea1a";
  color: rgb(0, 158, 84);
}
.icon-KP_251_Accepted .path2:before {
  content: "\ea1b";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_252_Declined .path1:before {
  content: "\ea1c";
  color: rgb(255, 0, 0);
}
.icon-KP_252_Declined .path2:before {
  content: "\ea1d";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_253_Declined-Reason:before {
  content: "\ea1e";
}
.icon-KP_254_Clock:before {
  content: "\ea1f";
}
.icon-KP_255_Business-Account:before {
  content: "\ea20";
}
.icon-KP_256_Institution-Account:before {
  content: "\ea21";
}
.icon-KP_257_Government-Account:before {
  content: "\ea22";
}
.icon-KP_258_About:before {
  content: "\ea23";
}
.icon-KP_259_Basic-Information:before {
  content: "\ea24";
}
.icon-KP_260_Contact-Information .path1:before {
  content: "\ea25";
  color: rgb(255, 255, 255);
}
.icon-KP_260_Contact-Information .path2:before {
  content: "\ea26";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_260_Contact-Information .path3:before {
  content: "\ea27";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_260_Contact-Information .path4:before {
  content: "\ea28";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_261_Education:before {
  content: "\ea29";
}
.icon-KP_262_Experience:before {
  content: "\ea2a";
}
.icon-KP_263_Other-Activities:before {
  content: "\ea2b";
}
.icon-KP_264_Card-Type:before {
  content: "\ea2c";
}
.icon-KP_265_Card-Color:before {
  content: "\ea2d";
}
.icon-KP_266_Personalize:before {
  content: "\ea2e";
}
.icon-KP_267_Data--Storage:before {
  content: "\ea2f";
}
.icon-KP_268_Account-Recovery-Settings:before {
  content: "\ea30";
}
.icon-KP_269_Change-Mobile-Number:before {
  content: "\ea31";
}
.icon-KP_270_Vacation-Response:before {
  content: "\ea32";
}
.icon-KP_271_Add-Other-Mail-Accounts:before {
  content: "\ea33";
}
.icon-KP_272_Delete-My-Account:before {
  content: "\ea34";
}
.icon-KP_273_User-Guide:before {
  content: "\ea35";
}
.icon-KP_274_FAQs:before {
  content: "\ea36";
}
.icon-KP_275_Video-Tutorials:before {
  content: "\ea37";
}
.icon-KP_276_Contact-Us:before {
  content: "\ea38";
}
.icon-KP_277_Personal-Account:before {
  content: "\ea39";
}
.icon-KP_278_Radio-Off:before {
  content: "\ea3a";
}
.icon-KP_279_Videos .path1:before {
  content: "\ea3b";
  color: rgb(255, 77, 77);
}
.icon-KP_279_Videos .path2:before {
  content: "\ea3c";
  margin-left: -1.001953125em;
  color: rgb(254, 168, 171);
}
.icon-KP_279_Videos .path3:before {
  content: "\ea3d";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_279_Videos .path4:before {
  content: "\ea3e";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_280_Radio-On:before {
  content: "\ea3f";
  color: #054694;
}
.icon-KP_281_Audios .path1:before {
  content: "\ea40";
  color: rgb(255, 193, 7);
}
.icon-KP_281_Audios .path2:before {
  content: "\ea41";
  margin-left: -1.001953125em;
  color: rgb(255, 236, 179);
}
.icon-KP_281_Audios .path3:before {
  content: "\ea42";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_282_Documents .path1:before {
  content: "\ea43";
  color: rgb(67, 120, 219);
}
.icon-KP_282_Documents .path2:before {
  content: "\ea44";
  margin-left: -1.001953125em;
  color: rgb(170, 200, 255);
}
.icon-KP_282_Documents .path3:before {
  content: "\ea45";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_283_Links .path1:before {
  content: "\ea46";
  color: rgb(88, 162, 222);
}
.icon-KP_283_Links .path2:before {
  content: "\ea47";
  margin-left: -1.001953125em;
  color: rgb(172, 212, 243);
}
.icon-KP_283_Links .path3:before {
  content: "\ea48";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_284_Login-History:before {
  content: "\ea49";
}
.icon-KP_285_Toggle-Off .path1:before {
  content: "\ea4a";
  color: rgb(0, 0, 0);
}
.icon-KP_285_Toggle-Off .path2:before {
  content: "\ea4b";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_286_Toggle-On:before {
  content: "\ea4c";
  color: #f00;
}
.icon-KP_287_Folder .path1:before {
  content: "\ea4d";
  color: rgb(255, 160, 0);
}
.icon-KP_287_Folder .path2:before {
  content: "\ea4e";
  margin-left: -1.001953125em;
  color: rgb(255, 202, 40);
}
.icon-KP_288_Images:before {
  content: "\ea4f";
}
.icon-KP_289_Toggle-On .path1:before {
  content: "\ea50";
  color: rgb(5, 70, 148);
}
.icon-KP_289_Toggle-On .path2:before {
  content: "\ea51";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_290_Create-Folder:before {
  content: "\ea52";
}
.icon-KP_291_Move:before {
  content: "\ea53";
}
.icon-KP_292_Move:before {
  content: "\ea54";
}
.icon-KP_293_Upload:before {
  content: "\ea55";
}
.icon-KP_294_Download:before {
  content: "\ea56";
}
.icon-KP_295_Cloud-Storage:before {
  content: "\ea57";
}
.icon-KP_296_SD-Card:before {
  content: "\ea58";
}
.icon-KP_297_Google-Cloud .path1:before {
  content: "\ea59";
  color: rgb(234, 67, 53);
}
.icon-KP_297_Google-Cloud .path2:before {
  content: "\ea5a";
  margin-left: -1.001953125em;
  color: rgb(66, 133, 244);
}
.icon-KP_297_Google-Cloud .path3:before {
  content: "\ea5b";
  margin-left: -1.001953125em;
  color: rgb(52, 168, 83);
}
.icon-KP_297_Google-Cloud .path4:before {
  content: "\ea5c";
  margin-left: -1.001953125em;
  color: rgb(251, 188, 5);
}
.icon-KP_298_I-Cloud:before {
  content: "\ea5d";
  color: #2196f3;
}
.icon-KP_299_TA-Wallet:before {
  content: "\ea5e";
  color: #33a32b;
}
.icon-KP_300_Android:before {
  content: "\ea5f";
}
.icon-KP_301_IOS:before {
  content: "\ea60";
}
.icon-KP_302_Chrome .path1:before {
  content: "\ea61";
  color: rgb(255, 255, 255);
}
.icon-KP_302_Chrome .path2:before {
  content: "\ea62";
  margin-left: -1.001953125em;
  color: rgb(34, 147, 66);
}
.icon-KP_302_Chrome .path3:before {
  content: "\ea63";
  margin-left: -1.001953125em;
  color: rgb(251, 193, 22);
}
.icon-KP_302_Chrome .path4:before {
  content: "\ea64";
  margin-left: -1.001953125em;
  color: rgb(26, 115, 232);
}
.icon-KP_302_Chrome .path5:before {
  content: "\ea65";
  margin-left: -1.001953125em;
  color: rgb(227, 59, 46);
}
.icon-KP_303_Safari .path1:before {
  content: "\ea66";
  color: rgb(30, 113, 243);
}
.icon-KP_303_Safari .path2:before {
  content: "\ea67";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_303_Safari .path3:before {
  content: "\ea68";
  margin-left: -1.001953125em;
  color: rgb(255, 0, 0);
}
.icon-KP_303_Safari .path4:before {
  content: "\ea69";
  margin-left: -1.001953125em;
  color: rgb(208, 20, 20);
}
.icon-KP_303_Safari .path5:before {
  content: "\ea6a";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_303_Safari .path6:before {
  content: "\ea6b";
  margin-left: -1.001953125em;
  color: rgb(172, 172, 172);
}
.icon-KP_304_Edge .path1:before {
  content: "\ea6c";
  color: rgb(0, 0, 0);
}
.icon-KP_304_Edge .path2:before {
  content: "\ea6d";
  margin-left: -1.001953125em;
  color: rgb(13, 71, 134);
}
.icon-KP_304_Edge .path3:before {
  content: "\ea6e";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_304_Edge .path4:before {
  content: "\ea6f";
  margin-left: -1.001953125em;
  color: rgb(12, 113, 185);
}
.icon-KP_304_Edge .path5:before {
  content: "\ea70";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_304_Edge .path6:before {
  content: "\ea71";
  margin-left: -1.001953125em;
  color: rgb(102, 235, 110);
}
.icon-KP_305_Opera .path1:before {
  content: "\ea72";
  color: rgb(255, 27, 45);
}
.icon-KP_305_Opera .path2:before {
  content: "\ea73";
  margin-left: -1.001953125em;
  color: rgb(156, 0, 0);
}
.icon-KP_306_Repeat:before {
  content: "\ea74";
}
.icon-KP_307_Repeat:before {
  content: "\ea75";
}
.icon-KP_308_Instant-Reply:before {
  content: "\ea76";
}
.icon-KP_309_Save:before {
  content: "\ea77";
}
.icon-KP_310_Maximize-Full:before {
  content: "\ea78";
}
.icon-KP_311_Maximize-Half:before {
  content: "\ea79";
}
.icon-KP_312_Minimize:before {
  content: "\ea7a";
}
.icon-KP_313:before {
  content: "\ea7b";
}
.icon-KP_314:before {
  content: "\ea7c";
}
.icon-KP_315:before {
  content: "\ea7d";
}
.icon-KP_316:before {
  content: "\ea7e";
}
.icon-KP_3161:before {
  content: "\e915";
  color: #585858;
}
.icon-KP_3162:before {
  content: "\e96f";
  color: #585858;
}
.icon-KP_3163:before {
  content: "\ea7f";
}
.icon-KP_3164:before {
  content: "\ea80";
}
.icon-KP_317:before {
  content: "\ea81";
}
.icon-KP_318:before {
  content: "\ea82";
}
.icon-KP_319:before {
  content: "\ea83";
}
.icon-KP_320:before {
  content: "\ea84";
}
.icon-KP_321 .path1:before {
  content: "\ea85";
  color: rgb(0, 0, 0);
}
.icon-KP_321 .path2:before {
  content: "\ea86";
  margin-left: -1.001953125em;
  color: rgb(0, 0, 0);
}
.icon-KP_321 .path3:before {
  content: "\ea87";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
.icon-KP_321 .path4:before {
  content: "\ea88";
  margin-left: -1.001953125em;
  color: rgb(255, 255, 255);
}
