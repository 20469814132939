.Icon_Style {
    font-size: 20px;
    cursor: pointer;
}

.white {
    color: white;
}

.black{
    color: black;
}

.Gradient_Style {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(180deg, rgba(1, 40, 100, 0.40) 0%, rgba(0, 32, 81, 0.40) 100%), linear-gradient(270deg, #2630A8 38.08%, #1C84DC 78.1%, #2630A8 103.12%), linear-gradient(180deg, #0043A8 0%, #002051 100%);
}

.NonGradient_Style {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: white
}

.Modal_WhiteDiv {
    padding: 25px 35px;
    border-radius: 10px;
    background-color: #fff;
}

.Modal_GreyDiv{
    border-radius: 10px;
    border: 1px solid #D0D0D0;
    background: #F4F4F4;
    padding: 25px 35px;
}

.modalComponent-header {
    border-bottom: 0px !important;
    background: #ffffff !important;
    padding: 9px 15px 0px 15px !important;
}

.modalComponent-title {
    font-size: 18px !important;
    color: #000000 !important;
}

.modal{
    --bs-modal-width: 50% !important;
} 