.Frequent-Label{
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 14px;
    font-weight: 700;
}

.Frequent-Arrow{
    font-size: 12px;
    background-color: #DCDCDC;
    
}

.UpArrow{
    border-radius: 50%;
    padding: 5px;
    color: var(--Blue, #054694);
}

.DownArrow{
    border-radius: 50%;
    padding: 7px 5px 3px 5px;
    color: #054694;
}

.LeftArrow{
    border-radius: 0 6px 6px 0;
    padding: 8px;
    text-align: center;
}

.RightArrow{
    border-radius: 6px 0 0 6px ;
    padding: 8px;
}


.border-bottom-fa {
    border-bottom: 1px solid #154998;    
}