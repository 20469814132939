

.Image-Size {
    width: 100%;
    /* height: 350px; */
    border-radius: 15px;
    object-fit: fill;
}

.Title-Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 28px;
    font-weight: 700;
    margin-left: 1%;
}

.Text_Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.slick-dots li button:before {
    font-size: 15px !important;
}

.slick-dots {
    bottom: -35px !important;
}


.two-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.Carousel .Icon-size {
    font-size: 25px;
}