
.Circle-Border{
    border-radius: 50%;
    padding: 2px;
}

.Viewed{
    border: 2px solid #ABABAB;
}

.NotViewed{
    border: 2px solid #00BE00;
}

.Red-Circle{
    border: 2px solid #FF4C00;
}

.Img{
    position: relative;
}

.Msg-Count{
    display: flex;
    justify-content: center;
    position: absolute;
    background: #B60228;
    border-radius: 50%;
    padding: 1px 4px; 
    left: 43px;
    top: 0;
}

.Msg-Count-Font {
    font-size: 11px;
    color: #fff;
    font-family: Nunito;
    font-weight: 500;
}

.UserName{
    color: var(--Primary-Text, var(--black-25, #252525));
    font-family: Nunito;
    font-size: 12px;
    font-weight: 700;
    white-space: no-wrap;
}

.Role{
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 12px;
    font-weight: 600;
}

.cursor{
    cursor: pointer;
}