
.background_color {
    background-color: #ececec;
    height: 100vh;
}

.overall-height {
    height: 91.8vh;
    border-top-left-radius: 15px;
}

.Backgroundclr_inside{
    background: #FFF;
    border-radius: 10px;
    /* overflow-y: scroll; */
    height: 100%;
}

.Backgroundclr_inside::-webkit-scrollbar {
    display: none;
}

.Katchup_Label {
    border-radius: 10px 0px 20px 0px;
    background: #252525;
    width: 28%;
}

.Katchup_Name {
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
    font-family: Nunito !important;
}

.Katchup_Icon {
    font-size: 16px;
    color: #fff;
}

.nav-item{
    width: 50% !important;
}

.nav-tabs .nav-link{
    width: 100%;
    border:none !important;
    font-family: Nunito !important;
}

.nav-link{
    color: #252525 var(--bs-nav-link-color ) !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    font-weight: 700 !important;
    font-family: Nunito !important;
    border-bottom: 3px solid #252525 !important;
}

.background-color {
    background-color: #F4F4F4;
}

.border-radius {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}



