
.comm_back {
    border-radius: 15px;
    background: #FFF;
    /* padding: 15px; */
}

.ecomm_font {
    color: #252525;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.meesho_background {
    border-radius: 10px;
    background: #F4F4F4;        
}

.dress_back {
    background: #FFF;
    border-radius: 10px;
}

.border {
    border: 1px solid #bababa;
    width: fit-content;
    padding: 5px;
    border-radius: 7px;
}

.font {
    color: #b6b3b3;
    font-size: 12px;
    font-family: Nunito;
    font-weight: 500;
}

.font_price {
    color: #333332;
    font-family: Nunito;
    font-weight: 600;
    font-size: 16px;
}

.font_style {
    color: #b6b3b3;
    font-size: 10px;
    font-family: Nunito;
    font-weight: 500;
}

.font_free {
    color: #989896;
    font-family: Nunito;
    font-size: 11px;
    font-weight: 400;
}

.font_rating {
    color: #fff;   
    background-color: orange; 
    border-radius: 15px;
    padding: 2px 8px;
}

.place_font {
    color: #333332;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 700;
}

.height-scroll {
    /* height: 88vh; */
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 15px
}

.height-scroll::-webkit-scrollbar {
    display: none;
}
