
.Date_alignment{
    border-radius: 10px;
    background-color: #252525;
    padding: 6px;
}

.Calender_icon{
    padding: 0px;
    top: 9px;
    left: 4px;
    font-size: 10px;
    position: absolute;
    font-family: Nunito;
    font-weight: 600;
}

.Inside_Date{
    position: relative;
    font-size: 20px;
}

.CalenderPostion{
    position: absolute;
}

.react-calendar{
    width: 28% !important;
    background: white;
    border: 1px solid #a0a096;
    font-family: 'Nunito';
}