/* .Diary-Label{
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 23px;
    font-weight: 600;
} */

.Diary-Icon{
    font-size: 20px;
    color: #585858;
}

.Dairy-Select{
    width: 143px;
    height: 40px;
}

.Dairy-SelectLabel{
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.Dairy-SelectIcon{
    font-size: 12px;
    color: #054694
}

.Diary_back {
    background-color: #fff;
}

.border-none {
    border: none;
    padding: 5px 8px;
}

.Diary-Percentage{
    color: #F00;
    text-align: center;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 700;
    border: 4px solid #ABABAB;
    border-radius: 50%;
    padding: 8px;
}

.Diary-Description{
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}

.Dairy-Container{
    border-radius: 15px;
    background: #FFF;
}

.Advertisement_Img{
    width: 100%;
    /* width: 518px;
    height: 108px; */
    border-radius: 10px;
}