
.header_back {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    
}

.header_icon {
    font-size: 22px;
}

.header_image {
    background-color: white;
    border-radius: 50px;
    padding: 2px;
}

.header_Title_Font {
    color: #252525;
    font-family: Nunito;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
}

.header_icon_down {
    font-size: 10px;
}

.header_font {
    color:  #252525;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.photo_Border {
    border: 2px solid #00BE00;
    border-radius: 50px;
}

.photo_style {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.open_width {
    width: 250px;
}

.close_width {
    width: 0px;
}

.offcanvas {
    width: 200px !important;
}

.menu {
    background-color: #fff;
    padding: 15px;
}

.position-absolute {
    position: absolute;
}

.menu_font {
    font-family: Nunito;
    font-size: 15px;
    font-weight: 500;
}

.menu_icon_font {
    font-size: 20px !important;
    color: #000;    
    background-color: #fff;
}

.menu_icon_font_back {
    background-color: #252525;
    color: #fff;
    padding: 5px 8px;
    font-size: 12px;
}

