
.Recent-LabelDate{
    color: var(--White, #FFF);
    font-family: Nunito;
    font-size: 11px;
    font-weight: 400;
}

.Recent-date{
    background-color: #252525;
    padding: 4px;
    border-right: 1px solid #fff;
}

.Recent-count{
    background-color: #252525;
    padding: 4px;
    border-radius: 0 0 10px 0;
}

.Inside_Scroll{
    overflow-y: scroll;
    height: 49vh;
}

.Inside_Scroll::-webkit-scrollbar{
    display: none;
}


.recent-MsgCount{
    color: #202020;
    font-size: 10px;
    font-weight: 700;
    border: 0.5px solid #ababaa;
    border-radius: 50%;
    padding: 3px 4px;
}

.recent-msgTime{
    color: #202020;
    font-family: Nunito;
    font-size: 12px;
    font-weight: 700;
}

.recent-Copies{
    font-size: 20px;
    color: #40BAEB;
}

.recent-Attach{
    font-size: 15px;
    color: #154998;
    font-weight: 600;
}

.chat-Container{
    border-bottom: 1px solid #cccccc;
    padding: 8px 0;
}

.cursor{
    cursor: pointer;
}