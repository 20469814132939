/* .Widget-Label{
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 23px;
    font-weight: 600;
} */

.Widget-Icon{
    font-size: 20px;
    color: #585858;
}

.Widgets-Icon{
    font-size: 25px;
    color: var(--Secondary-Text, #585858);

}

.Widgets-Menu{
    color: var(--Secondary-Text, #585858);
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
}