/* @import url('https://fonts.googleapis.com/css2?family=Inter&family=Nunito:wght@600&family=PT+Serif&display=swap'); */


/* //scrollbar style start */


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}


/* //scrollbar style end */

.h-90-vh {
    height: 90vh;
}

.Container {
    padding: 40px 75px;
}

.Back-Image {
    background-image: url('../Assets/images/signup/Back_Img.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.Img_Border {
    position: relative;
    padding: 10px;
    width: 60px;
}

.Img_Border::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 6px;
    background: linear-gradient(45deg, #00D1FF, #005AC7);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.Kpost-Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 28px;
    font-weight: 700;
}

.Signup_Main {
    padding: 25px 50px;
    background-color: #FFF;
    border-radius: 10px;
}

.Signup_Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
}

.KP_Font {
    color: var(--Secondary-Text, #585858);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.Dropdown_Font {
    color: var(--Primary-Text, var(--black-25, #252525));
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.font-size {
    font-size: 14px;
}

.Dropdown_Style {
    border: 1px solid #054694;
    border-radius: 4px;
    background-color: #EDF2F7;
}

.css-13cymwt-control {
    background-color: #EDF2F7 !important;
}

.Button_Style {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: #054694;
    padding: 8px;
}

.Button_Font {
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    background-color: #054694;
    border: none;
}

.Login_A_Font {
    color: #49494A;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.L_Font {
    color: var(--Blue, #054694);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.h-85 {
    height: 85%;
}

.IconColor {
    color: #054694;
}

.Verify_Style {
    color: #054694;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}

.Signup_height {
    height: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Signup_height::-webkit-scrollbar {
    display: none;
}

.Borber-Bottom {
    border-bottom: 1px solid #eae7e7;
    padding: 6px 0px;
    font-weight: 400;
    font-size: 12px;
}

.B_Bot {
    border-bottom: 1px solid #eae7e7;
    padding-bottom: 10px;
}

.Con_Font {
    color: #009E54;
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
}

.f-14 {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.Li_Font {
    color: var(--Secondary-Text, var(--black-25, #585858));
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.Li_Div_Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}


/* Style for Modal */

.Modal_Div {
    background-color: #fff;
    padding: 25px 35px;
    border-radius: 10px;
}

.modal-header {
    border-bottom: 0px !important;
    background: linear-gradient(180deg, rgba(1, 40, 100, 0.40) 0%, rgba(0, 32, 81, 0.40) 100%), linear-gradient(270deg, #2630A8 38.08%, #1C84DC 78.1%, #2630A8 103.12%), linear-gradient(180deg, #0043A8 0%, #002051 100%);
    padding: 9px 15px 0px 15px !important;
}

.modal.show .modal-dialog::after {
    padding: 0px 50px !important;
}

.modal-title {
    font-size: 18px !important;
    color: #fff !important;
}

.modal-content {
    border-radius: 15px !important;
}

.Icon_Style {
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.Otp_Font {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.NumberFont {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}

.Color {
    color: #054694;
    font-weight: 600;
}

.text-center {
    text-align: center;
}

.IconColor_Green {
    color: #009E54;
}

.Verify_Style_Green {
    color: #009E54;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}


/* Style for Modal */


/*  Logesh */

.nuntio-font {
    font-family: Nunito;
}

.category-label {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.17px
}

.category-sub-label {
    color: #585858;
    font-size: 16px;
    font-weight: 400;
}

.category-contentBox-layout {
    width: 100%;
    border-radius: 10px;
    background-color: rgba(217, 232, 248, 0.50);
}

.category-title {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
}

.category-content {
    font-size: 15px;
    font-weight: 400;
    color: #585858;
}

.category-link {
    color: #054694;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.Container {
    width: 100%;
    padding: 40px 75px;
}

.Height-Home-Page {
    height: 84%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Height-Home-Page::-webkit-scrollbar {
    display: none;
}


/*  Logesh */


/* sritharan */

.Vertical_fonts {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 28px;
    font-weight: 700;
}

.Select_account {
    color: var(--Secondary-Text, #585858);
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
}

.border_inside {
    border-radius: 10px;
    background: rgba(217, 232, 248, 0.50);
}

.personal_size {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
}

.Cursor_pointer {
    cursor: pointer;
}

.Inside_alignment {
    width: 100%;
    padding: 0px 25px;
}

.Container {
    padding: 40px 75px;
}


/* sritharan */


/* sritharan */

.Vertical_fonts {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 28px;
    font-weight: 700;
}

.Select_account {
    color: var(--Secondary-Text, #585858);
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
}

.Vertical_Main {
    padding: 25px 50px;
    background-color: #fff;
    border-radius: 10px;
}

.border_inside {
    border-radius: 10px;
    background: rgba(217, 232, 248, 0.50);
}

.personal_size {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
}


.Inside_alignment {
    width: 100%;
    padding: 0px 25px;
}

.Container {
    padding: 40px 75px;
}

.change_color {
    color: #054694;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 500;
}

.Checkbox_color {
    color: #49494A;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
}

.Submit_button {
    border-radius: 10px;
    background: #054694;
    color: #fff;
    padding: 22px 125.5px;
}

.No_wrap {
    white-space: nowrap;
}


/* sritharan */


/* Login -- Logesh */

.Modal_Pincode {
    color: white;
    background-color: #054694;
    padding: 6px 30px;
    border: 1px solid #E3E3E3;
    ;
    border-radius: 5px;
    font-family: Nunito;
}

.Modal_Cancel {
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    background: #EDEDED;
    padding: 6px 30px;
    color: var(--Primary-Text, var(--black-25, #252525));
}

.UserLogIN-Box {
    border-radius: 10px;
    background: #E6EDF5;
}

.Login-profile {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(5, 70, 148, 0.20);
}

.Login-userName {
    color: #000;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}

.Login-userId {
    color: #585858;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
}

.Login-needHelp {
    display: flex;
    justify-content: flex-end;
    color: #054694;
    font-family: Nunito;
    font-size: 12px;
    font-weight: 400;
    
}

.Login-Container {
    width: 474px;
    height: 478px;
    border-radius: 10px;
    background: #FFF;
}

.Login_height {
    height: 54%;
}

.Login-ModalLabel {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
}

.Login-ModalProfile {
    color: var(--black-25, #252525);
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
}

.Login-ModalLogin {
    color: #0067D2;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 500;
}

.Login-ModalProfileSec {
    border-radius: 10px;
    background: #E6EDF5;
}

.Login_otherId {
    color: #49494A;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.Login_need_Account {
    color: #054694;
}

.Login_NeedAc {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.Login_Back_Color {
    background-color: #E6EDF5;
    padding: 10px;
    border-radius: 10px;
}

.input_Icon-Padding{
    padding-right: 35px;
}

.modal-50w{
    width: 500px;
}
/* Login -- Logesh */