
.back-message-color {
    background-color: #fff;
    border-radius: 10px;
}

.message-name-color {
    border-radius: 16px 5px 19px 16px;
    background:#E7F5FE;
    padding: 5px 0px;
    /* box-shadow: 0px 0px 10.84058px 0px rgba(0, 0, 0, 0.08); */
}


.font {
    font-family: Nunito;
}

.name_font {
    font-size: 13px;
    font-weight: 600;
    color: #054694;
    padding-left: 5px;
}

.name_font_background {
    background: #E7F5FE;
    padding: 5px 0px;
    border-radius: 8px;
}

.ft-size-15 {
    font-size: 15px;
    font-weight: 500;
}

.sub_font {
    color: #676767;  
    font-size: 14px;
}

.time_font {
    color: #202020;
}

.copy_font {
    color: #054694;
    font-size: 14px;
    font-weight: 600;
}

.para_font {
    color: #252525;
    font-size: 13px;
    font-weight: 500;
}

.add_font {
    color: #424242;
    font-size: 11px;
    font-weight: 600;
}

.edit_font {
    color: #F00;
    font-size: 14px;
    font-weight: 700;
}

.date_font {
    font-size: 9px;
    font-weight: 800;
    color: #054694;
}

.edit_name_font {
    color: #008000;
    font-size: 13px;
    font-weight: 700;
}

.edit_name_font1 {
    color: #008000;
    font-size: 12px;
    font-weight: 700;
}

.add_back {
    border-radius: 9.855px 9.855px 9.855px 0px;
    background: var(--Blue-shade, #E7F5FE);
    padding: 5px;
}

.count_back {
    border-radius: 25px;
    border: 0.197px solid #054694;
    background: #FFF;
    /* box-shadow: 0px 0px 3.94203px 0px rgba(0, 0, 0, 0.08); */
}

.count_font {
    color: #054694;
    font-size: 9px;
    font-weight: 700;
    font-family: Nunito;
    padding: 3px;
}

.edit_para_outline {
    border-radius: 5px;
    border: 1px solid rgba(5, 70, 148, 0.30);
}

